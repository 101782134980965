/* Container for the entire App */
.App {
  font-size: 16px;
  font-family: Arial, sans-serif;
  color: #333;
  page-break-inside: avoid;
  overflow: hidden;
}

.container {
  display: flex;
  gap: 40px; /* Add spacing between the text/button and Poster */
  padding: 10px; /* Padding for better spacing around the content */
}

.instructions {
  padding-left: 0; /* Remove any left padding from the container */
  margin-left: 0; /* Remove any left margin from the container */
}

.instructions > li {
  margin-left: 20px; /* Ensure list items have no left margin */
  padding-left: 0; /* Ensure list items have no left padding */
  margin-bottom: 8px; /* Add space between items for better readability */
}

/* Container for the text and button */
.text-container {
  background-color: #f9f9f9; /* Light background to contrast with the poster */
  padding: 20px; /* Padding for inner space */
  border-radius: 8px; /* Rounded corners for better look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Style for the text description */
.text-description {
  margin-bottom: 16px; /* Space between the text and button */
  font-weight: bold; /* Make text more prominent */
  font-size: 18px; /* Slightly larger text for readability */
}

/* Style for the button */
.styled-button {
  padding: 10px 20px; /* Button padding */
  font-size: 16px; /* Adjust font size for the button text */
  color: #ffffff; /* White text color */
  background-color: #007bff; /* Blue background for button */
  border: none; /* Remove border for modern look */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor for interactivity */
  transition: background-color 0.3s ease; /* Smooth transition on hover */
}

.styled-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.tl-canvas {
  cursor: default !important; /* Prevent cursor change on canvas */
}